import {
    apiList,
    Api
} from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import sorttable from "@/components/sortTemplate.vue";
import paging from "@/components/pagings.vue";
import screenfull from 'screenfull'

// import { axios_file } from "@/assets/js/http_axios";
import inputnumber from '@/components/inputNumber.vue'
export default {
    inject: ["reload"],
    components: {
        // mytables,
        draggable,
        sorttable,
        paging,
        inputnumber,
    },
    data() {
        return {
            activeName: 'first', //  TAB标签标识
            number1: "",
            number2: "",
            isActive: true, //  输入框绑定动态class
            judge: false, //  输入框绑定动态class
            MoreConditions: '更多条件', //  输入框绑定动态class
            queryData: {
                courierNumber: "", //  快递单号
                endTime: '', //  添加日期-结束
                expressName: '', //  快递公司名称
                startTime: '', //  添加日期-开始
                storageId: '1', //  仓库id
            },
            tabelHeadeTitle: [ //  待认领列表表格默认字段
                {
                    name: "仓库",
                    field: "storageName",
                    width: "120",
                    sort: 2,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "快递公司",
                    field: "expressName",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },

                {
                    name: "快递单号",
                    field: "courierNumber",
                    width: "120",
                    sort: 5,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "包裹状态",
                    field: "stockStatusShow",
                    width: "120",
                    sort: 7,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                // {
                //     name: "(无)品名",
                //     field: "",
                //     width: "120",
                //     sort: 3,
                //     isShow: true,
                //     isTotal: false,
                //     isSort: true,
                // },
                {
                    name: "件数",
                    field: "count",
                    width: "120",
                    sort: 4,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "新建时间",
                    field: "createTime",
                    width: "160",
                    sort: 20,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "操作人",
                    field: "operator",
                    width: "120",
                    sort: 20,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },


            ],
            tableData: [ //  待认领列表数据

            ],
            singleData: [], //  表格选择的数据
            pageNum: 1, //  页码
            total: 0,
            sizeList: [10, 20, 50, 100, 200],
            size: 50, //  一页几条
            expressArr: [],
            //    ------ 确认认领  --------
            isDT: false, //  确认认领弹出窗状态1
            confirmForm: { //  表单数据
                storageId: '1'
            },
            editTitle: '认领确认',
            claimType: '',
            //    ------ 会员选择 --------
            isUserDT: false, //  会员弹出层标识
            pageStart: 1, //  页码
            pageTotal: 50, //  当前页条数
            pageCount: 0, //  总条数
            vf: {
                memberId: '',
                nickname: '',

            },
            vfr: {},
            ut: [],
            //  认领记录
            claimedData: [], //  认领记录表格数据
            tabelHeadeTitle1: [{
                name: "仓库",
                field: "storageName",
                width: "120",
                sort: 2,
                isShow: true,
                isTotal: false,
                isSort: true,

            },
            {
                name: "会员名称",
                field: "nickname",
                width: "120",
                sort: 3,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员号",
                field: "memberId",
                width: "120",
                sort: 4,
                isShow: true,
                isTotal: false,
                isSort: true,
            },

            {
                name: "快递公司",
                field: "expressName",
                width: "160",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },

            {
                name: "快递单号",
                field: "courierNumber",
                width: "160",
                sort: 5,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "包裹状态",
                field: "packageStatusShow",
                width: "160",
                sort: 5,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "签收时间",
                field: "signforTime",
                width: "160",
                sort: 5,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "签收人",
                field: "signforUserName",
                width: "160",
                sort: 5,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "货物类型",
                field: "itemcategoryName",
                width: "120",
                sort: 11,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "品名",
                field: "itemName",
                width: "120",
                sort: 11,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "货值",
                field: "unitPrice",
                width: "120",
                sort: 11,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "数量",
                field: "count",
                width: "120",
                sort: 11,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "包裹备注",
                field: "comment",
                width: "180",
                sort: 11,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "新建时间",
                field: "createTime",
                width: "160",
                sort: 20,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            ],
            pstart: 1, //  页码
            ptotal: 50, //  当前页条数
            pcount: 0, //  总条数
            // ======公用组件==========
            show_sortTableHeard: false, //显示弹窗
            show_sortTableHeard1: false, //显示弹窗
            show_upload: false, //显示上传弹窗
            myTableHeard: [], //我的表头
            originalTabelHeadeTitle: [],
            originalTabelHeadeTitle1: [],
            myTableHeard1: [], //我的表头
            selectLength: 0, //显示的长度
            selectLength1: 0, //显示的长度
            allCheck: false, //全选
            allCheck1: false, //全选
            tableHeigth: '50vh',
            totalArr: [], //需要合计的字段
            totalArr1: [], //需要合计的字段
            className: "", //导出需要
            className1: [],
            fieldName: [], //下载字段名
            head: [], //下载表头字段
            fileList: [],
            uploadUrl: "", //上传路径
            queryData1: {
                courierNumber: "", //  快递单号
                startTime: '',
                endTime: '',
                storageId: '1',
                expressName: '',
            },
            sortName1: '模板名', //排序名

            cangkuList: [],
            //       ---- 定制内容----
            pageGroupName: 'PAGE_BGRL', //  页面标识
            sortName: '模板名', //  排序名
            packIds: [], //批量签收id数组

            // 输入快递单号弹窗
            showInputBox: false, // 
            showInputBox2: false, // 
            time_second: [],
        };
    },
    updated() {
        if (this.show_sortTableHeard) {
            // 排序表头
            let arr = this.tabelHeadeTitle;
            for (var i = 0; i < arr.length; i++) {
                arr[i].sort = i + 1;
            }
            this.tabelHeadeTitle = arr;
        }
        if (this.show_sortTableHeard1) {
            // 排序表头
            let arr = this.tabelHeadeTitle1;
            for (var i = 0; i < arr.length; i++) {
                arr[i].sort = i + 1;
            }
            this.tabelHeadeTitle1 = arr;
        }

    },
    created() {
        this.myTableHeard = this.tabelHeadeTitle;
        this.myTableHeard1 = this.tabelHeadeTitle1;
        if (this.originalTabelHeadeTitle.length == 0) {
            this.originalTabelHeadeTitle = JSON.parse(
                JSON.stringify(this.tabelHeadeTitle)
            );
        }
        if (this.originalTabelHeadeTitle1.length == 0) {
            this.originalTabelHeadeTitle1 = JSON.parse(
                JSON.stringify(this.tabelHeadeTitle1)
            );
        }
        this.getGroupFindFieldSort(); //  获取用户自定义表头
        this.getStorageList(); //  仓库列表
        this.getExpressArr() //  获取快递公司
        let endtime = tools.getCurrentDate(); //今天的时间;
        let sarttime = tools.getFlexDate(-10); //15天前
        this.time_second = [sarttime, endtime];
        this.queryData.startTime = sarttime
        this.queryData.endTime = endtime
        this.queryData1.startTime = sarttime
        this.queryData1.endTime = endtime
        // this.queryData1.startTime = sarttime
        // this.queryData1.endTime = endtime

    },
    beforeDestroy() {

    },
    mounted() {
        this.getData();
        // window.onresize = () => {
        //     return (() => {
        //         this.fetTableHeight();
        //     })();
        // };
    },

    watch: {},
    methods: {

        //点击输入快递单号 (待认领)
        clickInput() {
            this.showInputBox = true
        },
        showInputBoxBtn(val) {
            this.showInputBox = false
            this.number1 = val;
            this.getData();
        },
        closeDiasInputVal(val) {
            this.showInputBox = val || false
        },
        //点击输入快递单号(认领记录)
        clickInput2() {
            this.showInputBox2 = true
        },
        showInputBoxBtn2(val) {
            this.showInputBox2 = false
            this.number2 = val;
            this.getClaimedPackageRecordList();
        },
        closeDiasInputVal2(val) {
            this.showInputBox2 = false;
        },


        getData() { // 获取数据
            this.queryData.pageStart = this.pageNum
            this.queryData.pageTotal = this.size
            let time_second = this.time_second || [];
            // if (time_second.length > 0) {
            //     this.queryData.startTime = time_second[0] + " 00:00:00"
            //     this.queryData.endTime = time_second[1] + " 23:59:59"
            // } else {
            //     this.queryData.startTime = '';
            //     this.queryData.endTime = ""
            // }
            let that = this;
            if (that.queryData.endTime) {
                that.queryData.endTime = that.queryData.endTime + " 23:59:59";
            }
            if (that.queryData.startTime) {
                that.queryData.startTime = that.queryData.startTime + " 00:00:00";
            }

            let arrs = tools.getOrderNum(this.number1) || [];
            this.queryData.courierNumber = arrs
            Api.unclaimedPackageList(this.queryData).then((res) => {
                if (res.data.status === "success") {
                    this.className = res.data.result.className || "";
                    this.tableData = res.data.result.data || [];
                    this.total = res.data.result.pageCount || 0;
                    this.fetTableHeight();
                }
            });

            if (that.queryData.endTime) {
                that.queryData.endTime = that.queryData.endTime.slice(0, 10);
            }
            if (that.queryData.startTime) {
                that.queryData.startTime = that.queryData.startTime.slice(0, 10);
            }
        },

        getStorageList() { //  获取仓库下拉列表
            Api.getStorageList().then((res) => {
                if (res.data.status === 'success') {
                    this.cangkuList = res.data.result || [];
                } else {
                    this.$message.error(`${res.data.message}--仓库下拉列表API`)
                }
            });
        },
        getExpressArr() { //  获取快递公司下拉
            Api.expressChooseList().then((res) => {
                if (res.data.status === 'success') {
                    this.expressArr = res.data.result || [];
                } else {
                    this.$message.error(`${res.data.message}--获取快递公司下拉`)
                }
            });
        },

        changTimes(e) {
            console.log(e);
            if (!e) {
                this.time_second = [];
            }
            this.queryBtn_ok();
        },

        queryBtn_ok() { // 查询按钮
            let nidlist = new Array
            nidlist = tools.getOrderNum(this.number1)
            this.queryData.courierNumber = nidlist
            this.pageNum = 1
            this.getData();
        },
        queryBtn_ok1() { // 查询按钮1
            let nidlist = new Array
            nidlist = tools.getOrderNum(this.number2)
            this.queryData1.courierNumber = nidlist
            this.pageNum = 1
            this.getClaimedPackageRecordList();
        },

        handleSelectionChange(e) { //  表格选择事件
            e.expressName || ''
            this.singleData = e
        },
        resetData() { //  查询框表单重置
            this.queryData = {
                courierNumber: "", //  快递单号
                endTime: '', //  添加日期-结束
                expressName: '', //  快递公司名称
                startTime: '', //  添加日期-开始
                storageId: '1', //  仓库id
            }
            this.number1 = ""
        },
        resetData1() { //  查询框表单重置
            this.queryData1 = {
                courierNumber: "", //  快递单号
                startTime: '',
                endTime: '',
                storageId: '1',
                expressName: '',
            }
            this.number2 = ""
        },

        more() { //  输入框绑定动态class
            this.fetTableHeight()
            this.isActive = !this.isActive
            this.judge = !this.judge
            if (!this.judge) {
                this.MoreConditions = '更多条件'
            } else {
                this.MoreConditions = '收起条件'
            }
        },

        //      ----------- 确认认领 -----------


        //认领一个
        Claim() {
            if (this.singleData.length === 1) {
                ({
                    expressName: this.confirmForm.expressName,
                    count: this.confirmForm.count,
                    courierNumber: this.confirmForm.courierNumber
                } = this.singleData[0]);
                this.editTitle = '认领确认'
                this.isDT = true
                this.claimType = 'one'
            } else {
                this.$message.warning('请勾选一条数据');
            }
        },
        //批量认领
        Claim_pl() {
            if (this.singleData.length > 0) {
                this.confirmForm.count = this.singleData.length;
                this.claimType = 'two'
                this.editTitle = '认领确认'
                this.isDT = true
                let ids = [];
                this.singleData.forEach((item, index) => {
                    ids.push(item.packageId)
                })
                this.packIds = ids
            } else {
                this.$message.warning('请至少勾选一条数据');
            }
        },


        //   -------- 编辑会员 -----------
        changeVip() { //  选择会员名称
            this.getVipList()
            this.isUserDT = true
        },

        getVipList() { //  获取会员列表
            this.vf.pageStart = this.pageStart
            this.vf.pageTotal = this.pageTotal
            Api.getMemberList(this.vf).then((res) => {
                if (res.data.status === 'success') {
                    this.ut = res.data.result.data
                    this.pageCount = res.data.result.pageCount || 0;
                }
            });
        },
        cxEdit() { //  查询按钮

            this.pageStart = 1
            this.getVipList()
        },


        xz(data) { //  会员表格选择事件
            this.confirmForm.memberId = data.memberId
            this.confirmForm.storageId = data.storageId
            this.isUserDT = false
        },
        handleClose() { //  会员弹出框关闭前回调事件
            this.resetForm('vf')
        },
        resetForm(formName) { //  公共表单重置事件
            this.$refs[formName].resetFields();
            if (formName === 'confirmForm') {
                this.confirmForm.memberId = ''
            }
        },

        //             ------ end -------


        comfirmSub() { //  确认
            // 单个
            if (this.claimType == 'one') {
                let parm = {
                    storageId: this.confirmForm.storageId || '1',
                    expressName: this.confirmForm.expressName || '',
                    count: this.confirmForm.count || '1',
                    courierNumber: this.confirmForm.courierNumber || '',
                    memberId: this.confirmForm.memberId || '',
                }
                let sign = tools.getSign(parm)
                parm.sign = sign
                Api.claimPackage(parm).then((res) => {
                    if (res.data.status === 'success') {
                        this.$message.success(res.data.message)
                        this.isDT = false
                        this.getData()
                    } else {
                        this.$message.error(`${res.data.message}--认领确认API`)
                    }
                })
            } else if (this.claimType == 'two') {
                // 批量签收
                let param = {
                    memberId: this.confirmForm.memberId,
                    packageIds: this.packIds || []
                }
                let sign = tools.getSign(param)
                param.sign = sign
                Api.batchClaimPackage(param).then(res => {
                    if (res.data.status === 'success') {
                        this.$message.success(res.data.message || '')
                        this.isDT = false
                        this.getData()
                    }
                })
            }


        },

        handleClick() { //  顶部标签页切换
            if (this.activeName === 'second') {
                this.myTableHeard1 = this.tabelHeadeTitle1
                this.pageGroupName = 'renling'
                this.getGroupFindFieldSort()
                // this.SignInRecord()
                this.getClaimedPackageRecordList()
            } else {
                this.pageGroupName = 'PAGE_BGRL'
                this.getGroupFindFieldSort()
                this.getData();
            }
            this.singleData = []
        },

        //  认领记录
        getClaimedPackageRecordList() { //  认领记录列表
            // let queryData1 = this.queryData1
            this.queryData1.pageStart = this.pstart
            this.queryData1.pageTotal = this.ptotal

            let arss = tools.getOrderNum(this.number2) || [];
            this.queryData1.courierNumber = arss

            let that = this;
            if (that.queryData1.endTime) {
                that.queryData1.endTime = that.queryData1.endTime + " 23:59:59";
            }
            if (that.queryData1.startTime) {
                that.queryData1.startTime = that.queryData1.startTime + " 00:00:00";
            }


            Api.claimedPackageRecordList(this.queryData1).then((res) => {
                if (res.data.status === "success") {
                    this.className1 = res.data.result.className || "";
                    this.claimedData = res.data.result.data || [];
                    this.pcount = res.data.result.pageCount || 0;
                    this.fetTableHeight();
                }
                // else {
                //     this.$message.error(`${res.data.message}--认领记录API`)
                // }
            });
            if (that.queryData1.endTime) {
                that.queryData1.endTime = that.queryData1.endTime.slice(0, 10);
            }
            if (that.queryData1.startTime) {
                that.queryData1.startTime = that.queryData1.startTime.slice(0, 10);
            }
        },

        refuseClaim() { //  撤销认领
            this.$confirm(`即将撤销${this.singleData.length}条认领数据, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let _data = {
                    packageIds: []
                }
                this.singleData.forEach(item => {
                    _data.packageIds.push(item.packageId)
                })
                _data.sign = tools.getSign(_data)
                Api.revokeClaimPackage(_data).then((res) => {
                    if (res.data.status === "success") {
                        this.$message.success(res.data.message)
                        this.getClaimedPackageRecordList()
                    } else {
                        this.$message.error(`${res.data.message}--撤销认领`)
                    }
                });
            })
        },

        // 获取状态展示值
        getStatusValList(status) {
            let param = {
                tableAndFieldName: status,
            };
            Api.getStatusValList(param).then((res) => {
                // 包裹状态
                if (status == "package_v1.package_status") {
                    let dataArr = res.data.result || [];
                    this.packageStatusList = dataArr
                }
                // 拒收单拒收状态
                if (status == "bill_rejection_v1.handle_status") {
                    let rejectionStatusList = res.data.result || [];
                    this.rejectionStatusList = rejectionStatusList;
                }
                // 验货单验货状态
                if (status == "bill_inspection_v1.check_status") {
                    let inspectionStatusList = res.data.result || [];
                    this.inspectionStatusList = inspectionStatusList
                }
            });

        },


        //删除
        deletebut() {
            if (this.singleData.length === 1) {
                this.$confirm('是否撤销认领, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let param = {
                        packageId: this.singleData[0].packageId
                    }
                    param.sign = tools.getSign(param)
                    Api.delPackage(param).then(res => {
                        if (res.data.status === 'success') {
                            this.$message.success(res.data.message)
                            this.getData()
                        } else {
                            this.$message.error(res.data.message)
                        }
                    })
                })
            } else {
                this.$message.warning('请选择一条数据')
            }
        },


        //添加包裹
        addPacks() {
            this.$router.push('/BusinessManagement/addPacks')
        },


        handleCommandTabs(m) {
            if (m == "a") {
                this.myModel()
            } else if (m == 'b') {
                this.exportBtn()
            } else if (m == 'c') {
                this.exportTemplateDownload()
            } else if (m == 'd') {
                this.upLoadBtn()
            } else {
                this.screen()
            }

        },
        //付款确认
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert("submit!");
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        //上传图片
        handleRemove(file, fileList) {
            //console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },

        //验货处理
        InspectionHandling() {
            this.$router.push("/BusinessManagement/Warehousing/InspectionHandling");
        },
        //导出操作
        handleCommand_dc(command) {
            if (command == 'dc') {
                // 导出
                this.exportBtn()
            } else if (command == 'dcmb') {
                // 下载模板
                this.exportTemplateDownload()
            } else if (command == 'scmb') {
                // 上传模板
                this.upLoadBtn()
            }
        },

        // ===============================================导出部分=开始======
        // 上传导出模板
        uploadSectionFile(param) {
            //console.log(param);
            const file = param.file,
                fileName = file.name;

            // 根据后台需求数据格式
            const form = new FormData();
            // 文件对象
            form.append("file", file);
            // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
            form.append("fileName", fileName);
            let signArr = [{
                key: "fileName",
                val: fileName,
            },];
            let sign = tools.getMD5Sign(signArr);
            form.append("sign", sign);
            // 项目封装的请求方法，下面做简单介绍
            Api.uploadFile(form)
                .then((res) => {
                    if (res.data.status == "success") {
                        let fileName = res.data.result.fileName;

                        let signArr = [{
                            key: "groupName",
                            val: this.pageGroupName,
                        },
                        {
                            key: "templateName",
                            val: this.sortName,
                        },
                        {
                            key: "fileName",
                            val: fileName,
                        },
                        ];
                        let sign_ = tools.getMD5Sign(signArr);
                        Api.saveExportTemplate({
                            groupName: this.pageGroupName,
                            templateName: this.sortName,
                            fileName: fileName,
                            sign: sign_,
                        }).then((res) => {
                            this.$message.success(res.data.message || "更新成功");
                            this.show_upload = false;
                        });
                    }
                })
                .catch((err) => { });
        },

        // 下载用户导出模板
        exportTemplateDownload() {
            var fieldName = this.fieldName;
            var head = this.head;
            if (head.length == 0) {
                let fieldNames = [];
                let heads = [];
                if (this.pageGroupName == 'PAGE_BGRL') {
                    for (var i = 0; i < this.myTableHeard.length; i++) {
                        if (this.myTableHeard[i].isShow) {
                            fieldNames.push(this.myTableHeard[i].field);
                            heads.push(this.myTableHeard[i].name);
                        }
                    }
                }
                if (this.pageGroupName == 'renling') {
                    for (var i = 0; i < this.myTableHeard1.length; i++) {
                        if (this.myTableHeard1[i].isShow) {
                            fieldNames.push(this.myTableHeard1[i].field);
                            heads.push(this.myTableHeard1[i].name);
                        }
                    }
                }

                fieldName = fieldNames;
                head = heads;
            }
            //加密数组格式
            let signArr = [{
                key: "fieldName",
                val: fieldName,
            },
            {
                key: "head",
                val: head,
            },
            ];
            let sign = tools.getMD5Sign(signArr);
            let param = {
                fieldName: fieldName,
                head: head,
                sign: sign,
            };
            Api.exportTemplateDownload(param).then((res) => {
                let downloadurl = res.data.result.fileUrl || "";
                //下载(这里其实是直接访问)
                var a = document.createElement("a");
                a.setAttribute("href", downloadurl);
                a.setAttribute("target", "_blank");
                a.click();
            });
        },
        // 真正导出
        templateDataExport(customizedExportId) {
            let className
            let that = this;
            let datas = []
            if (this.pageGroupName == 'PAGE_BGRL') {
                datas = that.tableData;
                className = that.className
            } else {
                datas = that.claimedData
                className = that.className1
            }
            let parm = {
                customizedExportId: customizedExportId, //模板id
                className: className,
                data: datas,
            };
            let signarr = tools.getSignArr(parm);
            let signs_e = tools.getMD5Sign(signarr);
            let array = {
                customizedExportId: customizedExportId,
                className: className,
                data: JSON.stringify(datas),
                sign: signs_e,
            }
            Api.templateDataExport(array).then((res) => {
                //下载(这里其实是直接访问)
                let downloadurl = res.data.result.fileUrl;
                var a = document.createElement("a");
                a.setAttribute("href", downloadurl);
                a.setAttribute("target", "_blank");
                a.click();

            });
        },
        // 上传按钮
        upLoadBtn() {
            this.show_upload = true;
        },
        // 点击导出按钮
        exportBtn() {
            console.log(this.pageGroupName)
            let that = this;
            let fieldName = [];
            let head = [];
            if (this.pageGroupName == 'PAGE_BGRL') {
                for (var i = 0; i < this.myTableHeard.length; i++) {
                    if (this.myTableHeard[i].isShow) {
                        fieldName.push(this.myTableHeard[i].field);
                        head.push(this.myTableHeard[i].name);
                    }
                }
            } else if (this.pageGroupName == 'renling') {
                for (var i = 0; i < this.myTableHeard1.length; i++) {
                    if (this.myTableHeard1[i].isShow) {
                        fieldName.push(this.myTableHeard1[i].field);
                        head.push(this.myTableHeard1[i].name);
                    }
                }
            }
            this.fieldName = fieldName;
            this.head = head;
            // 1,先获取用户导出模板
            Api.getUserExportTemplate({
                groupName: this.pageGroupName,
            }).then((res) => {
                if (res.data.result && res.data.result[0].customizedExportId) {
                    // 有自定义模板

                    let customizedExportId = res.data.result[0].customizedExportId;
                    that.templateDataExport(customizedExportId);


                } else {
                    if (this.pageGroupName == 'PAGE_BGRL') {
                        tools.dynamicExport(
                            this.myTableHeard,
                            this.className,
                            this.tableData,
                            "包裹待认领列表"
                        );
                    } else if (this.pageGroupName == 'renling') {
                        tools.dynamicExport(
                            this.myTableHeard1,
                            this.className1,
                            this.claimedData,
                            "包裹已认领列表"
                        );
                    }

                }
            });
        },
        // ===============================================导出部分=结束======

        /**
         * ******************************计算表格高度**开始*************************
         */
        // 重置table高度
        resetHeight() {
            return new Promise((resolve, reject) => {
                this.tableHeigth = 0;
                resolve();
            });
        },
        // 设置table高度
        fetTableHeight() {
            this.resetHeight().then((res) => {
                this.tableHeigth = this.getHeight();
                this.$nextTick(() => {
                    this.$refs.mytable.doLayout();
                });
            });
        },
        // 获取表格高度
        getHeight() {
            let windonHeight = document.body.clientHeight
            let queryHeigth = 50;
            let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
            if (this.judge) {
                queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
                if (this.activeName === 'second') {
                    // queryHeigth = this.$refs.queryCriteria1.offsetHeight; //查询框
                    queryHeigth = 20 //查询框
                }
            } else {
                queryHeigth = 50
            }
            let pagsHeigth = 60; //分页组件
            var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60 + 10 + 15;
            return Math.floor(windonHeight - otherHeight) || "50vh"; //
        },
        /**
         * ******************************计算表格高度**结束*************************
         */

        // 刷新页面==>
        reload_s() {
            this.reload(); //App.Vue里面的方法
        },


        // 提取快递单输入框的内容(无规则输入,定义了 换行,',' ,'空格三种')
        getStr(orderNos) {
            let arr = [];
            let arr_1 = [];
            if (orderNos.indexOf("\n") !== -1) {
                arr = orderNos.split("\n");
                //  ['123 344,dsg ewe','234 233','32,234']
                for (var i = 0; i < arr.length; i++) {
                    let arr_a = [];
                    if (arr[i].indexOf(",") !== -1) {
                        arr_a = arr[i].split(",");
                        arr_a.map((item) => {
                            arr_1.push(item);
                        });
                    } else {
                        if (arr[i]) {
                            arr_1.push(arr[i]);
                        }
                    }
                }
                setTimeout(() => {
                    for (var j = 0; j < arr_1.length; j++) {
                        let arr_b = [];
                        if (arr_1[j].indexOf(" ") !== -1) {
                            arr_b = arr_1[j].split(" ");
                            arr_b.map((item) => {
                                arr_b.push(item);
                            });
                        } else {
                            if (arr_1[j]) {
                                arr_b.push(arr_1[j]);
                            }
                        }
                    }
                }, 20);
            } else if (orderNos.indexOf(",") !== -1) {
                arr = orderNos.split(",");
                //  ['123 344','234','32 234']
                for (var b = 0; b < arr.length; b++) {
                    let arr_a = [];
                    if (arr[b].indexOf(" ") !== -1) {
                        arr_a = arr[b].split(" ");
                        arr_a.map((item) => {
                            arr_1.push(item);
                        });
                    } else {
                        if (arr[b]) {
                            arr_1.push(arr[b]);
                        }
                    }
                }
            } else if (orderNos.indexOf(" ") !== -1) {
                arr = orderNos.split(" ");
                arr.map((item) => {
                    if (item) {
                        arr_1.push(item);
                    }
                });
            } else {
                arr_1 = [orderNos];
            }
            let endArr = [];
            for (var q = 0; q < arr_1.length; q++) {
                if (arr_1[q].indexOf(" ") !== -1) {
                    let arrs = arr_1[q].split(" ");
                    for (var w = 0; w < arrs.length; w++) {
                        if (arrs[w]) {
                            endArr.push(arrs[w]);
                        }
                    }
                } else {
                    if (arr_1[q]) {
                        endArr.push(arr_1[q]);
                    }
                }
            }
            return endArr;
        },

        // //签收记录表头获取
        // SignInRecord() {
        //     this.myTableHeard1 = this.tabelHeadeTitle1;
        //     Api.getGroupFindFieldSort({
        //         groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        //     }).then((res) => {
        //         let arr = res.data.result || [];
        //         if (arr.length > 0) {
        //             if (arr[0].sortObject) {
        //                 this.myTableHeard1 = JSON.parse(arr[0].sortObject);
        //                 this.sortName1 = arr[0].sortName;
        //             }
        //         } else {
        //             this.myTableHeard1 = this.tabelHeadeTitle1;
        //         }
        //         let totalArr = [];
        //         //提取需要合计的字段
        //         for (var i = 0; i < this.myTableHeard1.length; i++) {
        //             if (this.myTableHeard1[i].isTotal) {
        //                 totalArr.push(this.myTableHeard1[i].field);
        //             }
        //         }
        //         this.totalArr1 = totalArr;
        //     });
        //
        //     this.$nextTick(() => {
        //         this.$refs.mytable.doLayout();
        //     });
        // },
        // 获取自定义的排序,没有就用默认的
        getGroupFindFieldSort() {
            Api.getGroupFindFieldSort({
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
            }).then((res) => {
                let arr = res.data.result || [];
                let STB = this.myTableHeard
                let ATB = this.tabelHeadeTitle
                let Total = this.totalArr
                let SN = this.sortName
                if (this.activeName === 'second') {
                    STB = this.myTableHeard1
                    ATB = this.tabelHeadeTitle1
                    Total = this.totalArr1
                    SN = this.sortName1
                }
                if (arr.length > 0) {
                    if (arr[0].sortObject) {
                        STB = JSON.parse(arr[0].sortObject);
                        SN = arr[0].sortName;
                    }
                } else {
                    STB = ATB
                }
                let totalArr = [];
                //提取需要合计的字段
                for (var i = 0; i < this.myTableHeard.length; i++) {
                    if (STB[i].isTotal) {
                        totalArr.push(STB[i].field);
                    }
                }
                Total = totalArr;
            });
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
        },
        // 设置模板按钮
        myModel() {
            this.show_sortTableHeard = true;
            this.tabelHeadeTitle = JSON.parse(JSON.stringify(this.myTableHeard));
            let selectLength = 0;
            for (var i = 0; i < this.tabelHeadeTitle.length; i++) {
                if (this.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            this.selectLength = selectLength;
            if (selectLength === this.tabelHeadeTitle.length) {
                this.allCheck = true;
            } else {
                this.allCheck = false;
            }
        },
        //设置模板按钮(签收记录)
        myModel1() {
            this.show_sortTableHeard1 = true;
            this.tabelHeadeTitle1 = JSON.parse(JSON.stringify(this.myTableHeard1));
            let selectLength = 0;
            for (var i = 0; i < this.tabelHeadeTitle1.length; i++) {
                if (this.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            this.selectLength1 = selectLength;
            if (selectLength === this.tabelHeadeTitle1.length) {
                this.allCheck1 = true;
            } else {
                this.allCheck1 = false;
            }
        },

        // 排序==>取消按钮(签收记录)
        cancelSortHeard1() {
            this.show_sortTableHeard1 = false;
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
        },

        // 排序==>确认按钮(签收记录)
        confirmOk1(e) {
            this.tabelHeadeTitle1 = e;
            this.myTableHeard1 = this.tabelHeadeTitle1;
            let sortObject = this.myTableHeard1;
            let signs = [{
                key: "sortName",
                val: this.sortName1, //模板名称
            },
            {
                key: "groupName",
                val: this.pageGroupName, //页面标识
            },
            {
                key: "sortObject",
                val: JSON.stringify(sortObject),
            },
            ];

            let sign = tools.getMD5Sign(signs);

            let param = {
                // apiPath: apiList.api_packageList, //综合查询接口路径
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
                sortName: this.sortName1, //综合查询接口路径
                sortObject: JSON.stringify(sortObject),
                sign: sign,
            };
            Api.pushFindFieldSort(param).then((res) => {
                this.$message.success(res.data.message || "操作成功");
                this.getGroupFindFieldSort();
            });
            this.show_sortTableHeard1 = false; //关闭弹窗
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
            this.show_sortTableHeard1 = false;
        },
        // 排序==>取消按钮
        cancelSortHeard() {
            this.show_sortTableHeard = false;
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
        },
        // 排序==>确认按钮
        confirmOk(e) {
            this.tabelHeadeTitle = e;
            this.myTableHeard = this.tabelHeadeTitle;
            let sortObject = this.myTableHeard;
            let signs = [{
                key: "sortName",
                val: this.sortName, //模板名称
            },
            {
                key: "groupName",
                val: this.pageGroupName, //页面标识
            },
            {
                key: "sortObject",
                val: JSON.stringify(sortObject),
            },
            ];

            let sign = tools.getMD5Sign(signs);

            let param = {
                // apiPath: apiList.api_packageList, //综合查询接口路径
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
                sortName: this.sortName, //综合查询接口路径
                sortObject: JSON.stringify(sortObject),
                sign: sign,
            };
            Api.pushFindFieldSort(param).then((res) => {
                this.$message.success(res.data.message || "操作成功");
                this.getGroupFindFieldSort();
            });
            this.show_sortTableHeard = false; //关闭弹窗
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
            this.show_sortTableHeard = false;
        },
        // 点击某一项
        checktab(idx, e) {
            let that = this;
            that.tabelHeadeTitle[idx].isShow = e;
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
            if (selectLength == that.tabelHeadeTitle.length) {
                that.allCheck = true;
            } else {
                that.allCheck = false;
            }
        },
        //全选
        checkAll(e) {
            let that = this;
            this.allCheck = e;
            for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
                this.tabelHeadeTitle[j].isShow = e;
            }
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
        },
        //关闭弹窗
        closeDias(e) {
            this.show_sortTableHeard = e;
        },
        //关闭弹窗1
        closeDias1(e) {
            this.show_sortTableHeard1 = e;
        },
        // 合计
        getSummaries(param) {
            let that = this;
            const {
                columns,
                data
            } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                for (var i = 0; i < that.totalArr.length; i++) {
                    if (column.property == that.totalArr[i]) {
                        if (!values.every((value) => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return tools.mathToFixed(tools.add(prev, curr), 2)
                                } else {
                                    return prev;
                                }
                            }, 0);
                            sums[index] += "";
                        } else {
                            sums[index] = "---";
                        }
                    }
                }
            });
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
            return sums;
        },
        // 点击某一项
        checktab1(idx, e) {
            let that = this;
            that.tabelHeadeTitle1[idx].isShow = e;
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
            if (selectLength == that.tabelHeadeTitle1.length) {
                that.allCheck1 = true;
            } else {
                that.allCheck1 = false;
            }
        },
        //全选
        checkAll1(e) {
            let that = this;
            this.allCheck1 = e;
            for (var j = 0; j < this.tabelHeadeTitle1.length; j++) {
                this.tabelHeadeTitle1[j].isShow = e;
            }
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
        },
        // 合计
        getSummaries1(param) {
            let that = this;
            const {
                columns,
                data
            } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                for (var i = 0; i < that.totalArr1.length; i++) {
                    if (column.property == that.totalArr1[i]) {
                        if (!values.every((value) => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return tools.mathToFixed(tools.add(prev, curr), 2)
                                } else {
                                    return prev;
                                }
                            }, 0);
                            sums[index] += "";
                        } else {
                            sums[index] = "---";
                        }
                    }
                }
            });
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
            return sums;
        },

        //分页
        handleSizeChange(val) {
            this.size = val;
            this.getData();
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getData();
        },
        //分页
        handleSizeChange_jL(val) {
            this.ptotal = val;
            this.getClaimedPackageRecordList();
        },
        handleCurrentChange_jL(val) {
            this.pstart = val;
            this.getClaimedPackageRecordList();
        },
        // 会员
        sizeChange(val) { //  分页事件
            this.pageTotal = val
            this.getVipList()
        },
        currentChange(val) { //  分页事件
            this.pageStart = val
            this.getVipList()
        },
        // // 上一页
        // handlePrevPage() {
        //     this.pageNum = this.pageNum - 1 || 1;
        //     this.getData();
        // },
        // // 下一页
        // handleNextPage() {
        //     this.pageNum = this.pageNum + 1 || 1;
        //     this.getData();
        // },
    }
}